import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Constant from "../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { submitOrderData } from "../store/SubmitOrderAction";
import { emptyUserinfoSlice } from "../store/slice/UserInfoSlice";
import { emptyQuote } from "../store/slice/quoteSlice.js";
import { Input, Popconfirm, Space, message, Button, Flex, Typography, Card, Skeleton } from "antd";
import { notEmpty, notNull } from "../sharedFunctions.js";
import { getReferenceNo } from '../utility/orderUtils';
import { isMedMenu } from "../utility/generalUtils.js";

const { Paragraph, Text, Link } = Typography;

const ProcessToCheckOut = ({ setShowTipMessage }) => {
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ready, setReady] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const priceIncludedTax = quote?.taxes.every((tax) => tax.price_included);

  useEffect(() => {if(notNull(quote)){setLoaded(true)}}, [quote])


  const hasItems = () => {
    if(quote?.items?.length >= 1){
      return true
    }else{
      console.log('failed hasItems')
      return false
    }
  }
  const firstNameSet =  () => {
    if(notEmpty(quote?.customer?.first_name)){
      return true
    }else{
      console.log('failed firstNameSet')
      return false
    }
  }
  const lastNameSet =  () => {
    if(notEmpty(quote?.customer?.last_name)){
      return true
    }else{
      console.log('failed lastNameSet')
      return false
    }
  }
  const emailSet =  () => {
    if(notEmpty(quote?.customer?.email)){
      return true
    }else{
      console.log('failed emailSet')
      return false
    }
  }
  const phoneSet =  () => {
    if(notEmpty(quote?.customer?.telephone)){
      return true
    }else{
      console.log('failed phoneSet')
      return false
    }
  }
  const paymentSet =  () => {
    if(notNull(quote?.payment?.requested_payment_method)){
      return true
    }else{
      console.log('failed paymentSet')
      return false
    }
  }

  const inLimit =  () => {
    if(quote?.limits?.exceeded_limits.length === 0){
      return true
    }else{
      console.log('failed inLimit')
      return false
    }
  }

  useEffect(() => {
    const valid = hasItems() &&
    firstNameSet() &&
    lastNameSet() &&
    emailSet() &&
    phoneSet() &&
    paymentSet() &&
    inLimit();
    setReady(valid)
  }, [quote])


  // Inside your component
  const totals = useSelector((store) => store?.quote?.info?.quote?.totals) || 0;

  const navigate = useNavigate();


  const dispatch = useDispatch();

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  const submitOrder = () => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
    let data = {}
    setConfirmLoading(true);
    const submitOrder = async () => {
      if(quote?.payment?.requested_payment_method === 'posabit_pay' && quote?.fulfillment?.tip_amount === null){
        setShowTipMessage(true)
        setConfirmLoading(false)
        return
      }
      const response = await dispatch(
        submitOrderData({
          slug: slugId,
          reference_no,
          payment: data,
        })
      );
      return response;
    }

    if (reference_no) {
      submitOrder()
      .then((response) => {
        if(response.payload.success){
          setConfirmLoading(false);
          dispatch(emptyUserinfoSlice())
          // dispatch(emptySubmitOrder())

          dispatch(emptyQuote())
          navigate("/thank-you");
        }else{
          setConfirmLoading(false);
          message.error({
            content: notEmpty(response.payload.message) ? response.payload.message : 'Your payment method failed, please verify your account and try again',
            icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
          });
        }
      })
      .catch((error) => {
        message.error({
          content: 'Failed to fetch submit order',
          icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
        });
      });
      }
    }

  const confirmPrice = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("MerchantToken", sessionStorage.getItem("merchantToken"));
    return headers;
  }

  const isPosaBitPay = () =>  quote?.payment?.requested_payment_method === 'posabit_pay';
  const tipCalculator = () => {
    let tipFinal = quote?.fulfillment?.tip_calculated
    if (notNull(tipFinal)) {
      return parseFloat(tipFinal) / 100;
    }else{
      return 0
    }
  }

  const totalCalculated = () => {
    let tip = 0
    let fee = 0
    if (quote?.payment?.requested_payment_method === 'posabit_pay'){
      tip = quote?.fulfillment?.tip_calculated || 0
      fee = quote?.fulfillment?.processing_fee || 0
    }
    return (totals.total + tip + fee) / 100;
  }

  return (
    <Card
      title={Constant.CART_TOTALS}
      className='dark-border'
      style={{width: '100%'}}
    >
      <Flex justify="space-between">
        <Paragraph>{Constant.SUBTOTAL}</Paragraph>
        <Paragraph>
          {
            loaded ?
            <>${(totals?.subtotal / 100).toFixed(2)}</>
            :
            <Skeleton.Input active={true} />
          }
          </Paragraph>
      </Flex>
      {
        totals?.discount > 0 &&
        <>
        <Flex justify="space-between">
          <Paragraph type='success'>{Constant.DISCOUNT}</Paragraph>
          <Paragraph type='success'>
            {
              loaded ?
              <>{Constant.MINUS_DOLLAR}{(totals?.discount / 100).toFixed(2)}</>
              :
              <Skeleton.Input active={true} />
            }
          </Paragraph>
        </Flex>
        </>
      }
      <Flex justify="space-between">
        <Paragraph>{priceIncludedTax ? Constant.TAX_INCLUDED_IN_PRICE : Constant.TAX}</Paragraph>
        <Paragraph>
          {
            loaded ?
            <>${(totals?.tax / 100).toFixed(2)}</>
            :
            <Skeleton.Input active={true} />
          }
        </Paragraph>
      </Flex>
      {
        isPosaBitPay() &&
        <>
          <Flex justify="space-between">
            <Paragraph>Tip:</Paragraph>
            <Paragraph>
              {
                loaded ?
                <>{Constant.DOLLAR}{tipCalculator().toFixed(2)}</>
                :
                <Skeleton.Input active={true} />
              }
            </Paragraph>
          </Flex>
          <Flex justify="space-between">
            <Paragraph>Fee:</Paragraph>
            <Paragraph>
              {
                loaded ?
                <>{Constant.DOLLAR}{(parseFloat(quote?.fulfillment?.processing_fee) / 100).toFixed(2)}</>
                :
                <Skeleton.Input active={true} />
              }
            </Paragraph>
          </Flex>
        </>
      }
      <Flex justify="space-between">
        <Paragraph strong>{Constant.TOTAL}</Paragraph>
        <Paragraph strong>
          {
            loaded ?
            <>{Constant.DOLLAR}{totalCalculated().toFixed(2)}</>
            :
            <Skeleton.Input active={true} />
          }
        </Paragraph>
      </Flex>
      {
        isMedMenu() &&
        <Space direction='vertical' size={0} style={{marginTop: 20, marginBottom: 20}}>
          <Text strong>Reminder:</Text>
          <Text>Please have your medical card ready upon pickup.</Text>
        </Space>
      }
      <Flex align="center" justify="center" vertical>
        <Button
          type="primary"
          size='large'
          block
          style={{
            padding: 30,
            paddingBottom: 30,
            // width: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            marginBottom: 20
          }}
          disabled={!ready}
          loading={confirmLoading}
          onClick={() => {
            submitOrder()
          }}
        >
          {Constant.PROCEED_TO_CHECKOUT}
        </Button>
        {/* <Text>
          By placing an order, you agree to {merchantName}'s <Link>Privacy Policy</Link>
        </Text> */}
      </Flex>
    </Card>
  );
};
export default ProcessToCheckOut;
