import { Flex, Space, Typography, Button, Modal } from 'antd';
import React from 'react'

const { Text } = Typography;

export default function NewPosabitPayModal({ newPosabitPayShow, setNewPosabitPayShow, setPaymentModalShow, posabitPayPhone }) {
    return (
      <Modal
        open={newPosabitPayShow}
        title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
        onCancel={() => setNewPosabitPayShow(false)}
        zIndex={1500}
        styles={{content: {padding: 50}}}
        footer={null}
        width={350}
      >
        <Space direction='vertical' size='large' style={{marginTop: 20}}>
        <Text style={{marginTop: 20, marginBottom: 20}}>Welcome - Looks like you are New!</Text>
        <Text style={{marginTop: 20, marginBottom: 20}}>{posabitPayPhone} does not have an account yet</Text>

        <Button block size='large' type='primary'
          onClick={() => {
            window.open('https://secure.posabit.com/SignUp', '_blank');
          }}
        >Sign Up</Button>
        <Button block size='large' type='default'
          onClick={() => {
            setNewPosabitPayShow(false)
            setPaymentModalShow(true)
          }}
        >
          Try Different Number
        </Button>
        </Space>
      </Modal>
    )
}
