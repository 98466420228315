import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { InputNumber, Radio, Typography, Space, Flex, Button, Divider, Modal } from "antd";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { notEmpty, notNull } from "../sharedFunctions.js";
import { getReferenceNo } from '../utility/orderUtils';
import { isMobile } from 'react-device-detect';

const { Text, Paragraph, Title } = Typography;

const Payment = ({ setPaymentModalShow, setPlaidModalShow, showTipMessage, setShowTipMessage}) => {
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const settings = useSelector((store) => store?.content?.contentData?.menu);
  const [currentQuote, setCurrentQuote] = useState(quote)
  const [feeAmount, setFeeAmount] = useState(0)
  const [tipSettings, setTipSettings] = useState()
  const columnWidth = isMobile ? '100%' : '80%';
  const [tipAmount, setTipAmount] = useState(quote?.fulfillment?.tip_amount)
  const [tipType, setTipType] = useState(quote?.fulfillment?.tip_type || 'percent')
  const [paymentMethod, setPaymentMethod] = useState(quote?.payment?.requested_payment_method);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [hidePaymentOptions, setHidePaymentOptions] = useState(false);
  const posabitPayAuthorized = quote?.payment?.posabit_pay_authorized

  useEffect(() => {
    if(tipSettings && tipType === 'amount' && !Object.values(tipSettings).includes(tipAmount)){
      setShowCustomTip(true)
    }
  },[tipSettings, tipAmount, tipType])

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);

  const dispatch = useDispatch();
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  useEffect(() => {
    if(quote !== currentQuote){
      setCurrentQuote(quote)
    }
  }, [quote])

  useEffect(() => {
    if (notNull(currentQuote) && (currentQuote?.payment?.requested_payment_method !== paymentMethod || currentQuote?.fulfillment?.tip_amount !== tipAmount || currentQuote?.fulfillment?.tip_type !== tipType)) {
      setPaymentMethod(currentQuote?.payment?.requested_payment_method)
      setTipAmount(currentQuote?.fulfillment?.tip_amount)
      setTipType(currentQuote?.fulfillment?.tip_type)
    }

    if(currentQuote?.payment?.requested_payment_method === 'posabit_pay'){
      setHidePaymentOptions(true)
    }
    if( notNull(currentQuote)){changePaymentMethod(currentQuote?.payment?.requested_payment_method)}
  }, [currentQuote])


  const updateQuote = useCallback(() => {
    if (notNull(reference_no)) {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
            quote: {
              payment: {
                requested_payment_method: paymentMethod,
              },
            },
        })
      );
    }
  }, [reference_no, defaultSlug, dispatch, paymentMethod])

  useEffect(() => {
    if (notNull(quote) && (quote?.payment?.requested_payment_method !== paymentMethod || quote?.fulfillment?.tip_amount !== tipAmount || quote?.fulfillment?.tip_type !== tipType)) {
      setPaymentMethod(quote?.payment?.requested_payment_method)
      setTipAmount(quote?.fulfillment?.tip_amount)
      setTipType(quote?.fulfillment?.tip_type)
    }
  }, [quote, paymentMethod, tipAmount, tipType])

  useEffect(() => {
    if(notEmpty(paymentMethod)){
    // if(notEmpty(paymentMethod) && notNull(quote) && quote?.payment?.requested_payment_method !== paymentMethod){
      updateQuote()
    }
  }, [paymentMethod, updateQuote])

  const changePaymentMethod = (method) => {
    if(method !== 'posabit_pay' && tipAmount > 0){
      setTipAmount(0)
      setTipType('percent')
      // updateTip()
    }
    setPaymentMethod(method)
  };

  useEffect(() => {
    axios.get(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${sessionStorage.getItem('selectedVenue')}/v1/info/posabit_pay`, {
      headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
        },
    })
    .then(res => {
      const feeSettings = res.data?.posabit_pay?.feeSettings
      setFeeAmount(feeSettings?.feePaidByConsumer?.value)
      setTipSettings(feeSettings?.tipOptions)
    })
    .catch(err => {
      console.log({err})
    })
  }, [])


  const updateTip = useCallback(() => {
    if(notNull(tipAmount)){setShowTipMessage(false)}
    if (reference_no) {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
            quote: {
              fulfillment: {
                tip_amount: tipAmount,
                tip_type: tipType,
                processing_fee: feeAmount * 100
              },
            },
        })
      );
    }
  }, [reference_no, defaultSlug, dispatch, tipAmount, tipType, feeAmount]);

  useEffect(() => {updateTip()}, [tipAmount, tipType, feeAmount, updateTip])


  const handleTipChange = (e) => {
    const val = notNull(e?.target?.value) ? e.target.value : e;
    if(val === 'other') {
      setShowCustomTip(true);
      setTipAmount(5);
      setTipType('amount');
    }else if(val === 'none') {
      setShowCustomTip(false);
      setTipAmount(0);
      setTipType('percent');
    }else {
      setShowCustomTip(false);
      setTipAmount(parseInt(val))
      setTipType(tipSettings.mode === 1 ? 'amount' : 'percent')
    }
  }

  const handlePosabitPay = () => {
    if(posabitPayAuthorized){
      changePaymentMethod('posabit_pay')
    }else{
      setPaymentModalShow(true)
    }
  }

  return (
    <div >
      <Flex justify='space-between'>
        <Title level={4}>Payment</Title>
        <Button type='link' onClick={() => setHidePaymentOptions(false)} style={{padding: 0}}>
          <Text type='secondary'>Change Payment</Text>
        </Button>
      </Flex>
      <Flex align="center" justify="center" vertical style={{width: columnWidth, padding: 0, margin: 'auto'}}>
        {/* <Alert message={<>
          <Text>Save 10% off today's order!</Text> <Text strong>Sign up & pay with POSaBit Pay to redeem.</Text>
          </>} type="success" showIcon  style={{width: '95%', marginBottom: 20}}
        /> */}
        {
          hidePaymentOptions ?
          <>
            <Flex align='flex-start' style={{width: '100%'}} vertical>
              <img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 150}}/>
              <Space style={{marginTop: 20}}>
                <Text>Tip:</Text>
                {
                  showTipMessage &&
                  <Text type='danger' italic>Please Select a tip option</Text>
                }
              </Space>
              <Radio.Group
                size={isMobile ? 'small' : 'middle'}
                onChange={handleTipChange}
                // defaultValue={tipSettings && Object.values(tipSettings).includes(tipAmount) ? tipAmount : 'other'}
                // defaultValue={tipAmount === 0 ? 'none' : tipAmount}
              >
                {
                  (notNull(tipSettings) && tipSettings.mode > 0) && Object.keys(tipSettings)?.map(tip => {
                    if(tip !== 'mode' && tipSettings[tip] > 0){
                      if(tipSettings.mode === 1){
                        return <Radio.Button key={tip} value={tipSettings[tip]}>{`$${tipSettings[tip].toFixed(2)}`}</Radio.Button>
                      }else{
                        return <Radio.Button key={tip} value={tipSettings[tip]}>{tipSettings[tip]}%</Radio.Button>
                      }
                    }else{ return null}
                  })
                }
                <Radio.Button value={'other'}>Other</Radio.Button>
                <Radio.Button value={'none'}>None</Radio.Button>
              </Radio.Group>

              {showCustomTip && (
                <div>
                <InputNumber
                  size='large'
                  min={0}
                  style={{
                    width: isMobile ? '100%' : '333px',
                    marginTop: 10
                  }}
                  placeholder="5.00"
                  precision={2}
                  prefix={'$'}
                  value={tipAmount}
                  onChange={(e) => {
                    setTipAmount(e)
                    setTipType('amount')
                    // updateTip()
                  }}
                />
                </div>
              )}
            </Flex>
          </>
          :
          <>
          <Button block size='large'
            onClick={handlePosabitPay}
            style={{marginBottom:12, borderColor: paymentMethod === 'posabit_pay' && settings?.primary_color}}
            >
              <img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 130}}/>
            </Button>
            {
              posabitPayAuthorized && paymentMethod !== 'posabit_pay' &&
              <Space direction='horizontal' style={{marginBottom: 0, paddingLeft: 25}}>
                <Text style={{fontSize: 14}}>Pay by Bank</Text>
                <Text style={{fontSize: 12}} type='secondary'>Powered by</Text>
                <img src="/images/Plaid_logo.svg.png" alt="plaid" style={{width: 60}}/>
                <Text type='secondary'>
                  <i className="fa fa-info-circle" style={{width: 15}} onClick={() => setPlaidModalShow(true)}/>
                </Text>
              </Space>
            }
            {
              !hidePaymentOptions &&
              <Divider style={{borderColor: '#d9d9d9'}}><Text type='secondary'>Or</Text></Divider>
            }
            {
              !hidePaymentOptions &&
              <>
              <Space direction='vertical' style={{width: '100%'}}>
                <Button
                block
                onClick={() => changePaymentMethod('cash')}
                style={{textAlign: 'left', borderColor: paymentMethod === 'cash' && settings?.primary_color}}
                size="large"
                >
                  Cash
                </Button>
                <Button
                block
                onClick={() => changePaymentMethod('debit')}
                style={{textAlign: 'left', borderColor: paymentMethod === 'debit' && settings?.primary_color}}
                size="large"
                >
                  Debit
                </Button>
              </Space>
              </>
            }
          </>
        }
      </Flex>

      <Modal
        open={modalShow}
        title={null}
        styles={{content: {padding: 0}}}
        onOk={() => setModalShow(false)}
        onCancel={() => setModalShow(false)}
        footer={null}
        zIndex={1500}
        className="posabit-pay-modal"
      >
        <img src="/images/PosabitPayModal.png" alt="Posabit Pay Info" style={{width: '100%'}} />
      </Modal>
    </div>
  );
};
export default Payment;

