import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import UserInformation from "./UserInformation";
import AdditionalNotes from "./AdditionalNotes";
import Payment from "./Payment";
import Review from "./Review";
import ProcessToCheckOut from "./ProcessToCheckOut";
import { Collapse, Typography, Space, Button, Avatar, Skeleton, Row, Col, Divider, Modal, Flex, Card, InputNumber, Input, message, Alert, FloatButton, Drawer } from 'antd';
import * as Constant from "../Constants";
import { fetchQuoteCartInfoApi } from "../store/GetQuoteAction";
import { primaryColor } from "../store/slice/ContentSlice";
import { getReferenceNo, setReferenceNo } from '../utility/orderUtils';
import { notNull, notEmpty } from "../sharedFunctions";
import { current } from "@reduxjs/toolkit";
import axios from "axios";
import { isMedMenu } from "../utility/generalUtils";
import { postPosabitPaySignin, putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import TextArea from "antd/es/input/TextArea";
import { isMobile } from "react-device-detect";
import OtpSubmitModal from "./OtpSubmitModal";
import NewPosabitPayModal from "./NewPosabitPayModal";
import ExpressCheckoutModal from "./ExpressCheckoutModal";
import DisabledPosabitPayModal from "./DisabledPosabitPayModal";
import ManageAccountModal from "./ManageAccountModal";

const { Title, Text, Paragraph } = Typography;

const CheckOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const [quote, setQuote] = useState(null);
  const [code, setCode] = useState(null);
  const location = useLocation()
  const [notes, setNotes] = useState('');
  const [activePanel, setActivePanel] = useState(1);
  const [showTipMessage, setShowTipMessage] = useState(false);
  const [plaidModalShow, setPlaidModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [newPosabitPayShow, setNewPosabitPayShow] = useState(false);
  const [disabledModalShow, setDisabledModalShow] = useState(false);
  const [manageAccountShow, setManageAccountShow] = useState(false);
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [customer, setCustomer] = useState({});
  const [otp, setOtp] = useState('');
  const [posabitPayPhone, setPosabitPayPhone] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [userFinished, setUserFinished] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const currentQuote = useSelector((store) => store?.quote?.info?.quote);
  const isPosabitPay = currentQuote?.payment?.requested_payment_method === 'posabit_pay';
  const slugId = useSelector(
      (store) =>
        store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
          ?.slug
    );

  useEffect(() => {
    if (notNull(quote)) {
      setNotes(quote?.notes || '');
    }
  }, [quote]);



  const reference_no = getReferenceNo(venue_id);

  useEffect(() => {
    if (quote && reference_no) {
      setReferenceNo(venue_id, quote?.reference_no);
    }
    if (notEmpty(currentQuote?.customer?.first_name) && notEmpty(currentQuote?.customer?.last_name) && notEmpty(currentQuote?.customer?.email) && notEmpty(currentQuote?.customer?.telephone)) {
      setUserFinished(true)
    }else{
      setUserFinished(false)
    }
    if (notNull(currentQuote?.payment?.requested_payment_method)) {
      if(isPosabitPay){
        if(notNull(code)) {setPaymentReady(true)}
        else {setPaymentReady(false)}
      }else {setPaymentReady(true)}
    }
    if(!loaded && notNull(currentQuote)){
      setLoaded(true)
    }
  }, [currentQuote, code, quote, reference_no, venue_id, loaded]);

  useEffect(() => {
    const payload = {reference_no: reference_no}
    dispatch(fetchQuoteCartInfoApi(reference_no, payload))
    setQuote(currentQuote)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    const getCodeFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const codeFromUrl = searchParams.get('code');

      if (codeFromUrl) {
        setCode(codeFromUrl);
        setActivePanel(3);
      }
    };
    getCodeFromUrl();
  }, [location.search]);


  const submitPhoneNumber = () => {
    axios.get(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${sessionStorage.getItem('selectedVenue')}/v1/info/verify_customer`, {
      headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
        },
      params: {
        phone_number: posabitPayPhone
      }
    })
    .then(res => {
      console.log({res})
      if(res?.data?.hasProfile && res?.data?.success){
        setCustomer({
          first_name: res?.data?.consumer?.firstName,
          last_name: res?.data?.consumer?.lastName,
          email: res?.data?.consumer?.email,
          telephone: posabitPayPhone.toString(),
          customer_type: isMedMenu() ? "medical" : "recreational",
        })
        setPaymentModalShow(false)
        setOtpModalShow(true)
      }else{
        setPaymentModalShow(false)
        setNewPosabitPayShow(true)
      }
    })
    .catch(err => {
      console.log({err})
    })
  }


  return (
    <>

    <div className="App payemnt_checkout_page">
      <div className="container">
        <nav aria-label="breadcrumb">
          <div className="back-btn">
            <Link onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"/> Back
            </Link>
          </div>
        </nav>
        <Title level={3} style={{marginTop: 20}}>Checkout</Title>
        <Divider className='dark-border'/>
        <Row gutter={20}>
          <Col span={isMobile ? 24 : 12}>
          <Title level={4}>Customer Information</Title>
            <UserInformation setPaymentModalShow={setPaymentModalShow} setPlaidModalShow={setPlaidModalShow}/>
            <Divider style={{borderColor: '#d9d9d9'}} />
            <Payment setPaymentModalShow={setPaymentModalShow} setPlaidModalShow={setPlaidModalShow} showTipMessage={showTipMessage} setShowTipMessage={setShowTipMessage}/>
            <Divider style={{borderColor: '#d9d9d9'}} />
            { isMobile &&
              <AdditionalNotes />
            }
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Space direction="vertical" style={{width: '100%'}}>
              {
                isMobile &&
                <Review/>
              }
              <ProcessToCheckOut otp={otp} setShowTipMessage={setShowTipMessage}/>
              {
                !isMobile &&
                <Review/>
              }
            </Space>
          </Col>
        </Row>
      </div>
    </div>
    <Modal
      open={plaidModalShow}
      title={null}
      styles={{content: {padding: 0}}}
      onOk={() => setPlaidModalShow(false)}
      onCancel={() => setPlaidModalShow(false)}
      footer={null}
      zIndex={1500}
      className="posabit-pay-modal"
    >
      <img src="/images/PosabitPayModal.png" alt="Posabit Pay Info" style={{width: '100%'}} />
    </Modal>

    <DisabledPosabitPayModal disabledModalShow={disabledModalShow} setDisabledModalShow={setDisabledModalShow}/>
    <ManageAccountModal manageAccountShow={manageAccountShow} setManageAccountShow={setManageAccountShow}/>
    <ExpressCheckoutModal
      paymentModalShow={paymentModalShow}
      setPaymentModalShow={setPaymentModalShow}
      posabitPayPhone={posabitPayPhone}
      setPosabitPayPhone={setPosabitPayPhone}
      submitPhoneNumber={submitPhoneNumber}
    />
    <NewPosabitPayModal
      newPosabitPayShow={newPosabitPayShow}
      setNewPosabitPayShow={setNewPosabitPayShow}
      setPaymentModalShow={setPaymentModalShow}
      posabitPayPhone={posabitPayPhone}
    />
    <OtpSubmitModal
      otpModalShow={otpModalShow}
      setOtpModalShow={setOtpModalShow}
      submitPhoneNumber={submitPhoneNumber}
      posabitPayPhone={posabitPayPhone}
    />
    {/* {
      !showDrawer &&
      <FloatButton
      shape="square"
      type="primary"
      style={{width: '90%'}}
      description={<Text level={4} style={{color: 'white'}}>Place Order</Text>}
      onClick={() => setShowDrawer(true)}
      />
    } */}
    <Drawer
      title="Place Order"
      placement="bottom"
      closable={true}
      onClose={() => setShowDrawer(false)}
      open={showDrawer}
      height={530}
    >
      <ProcessToCheckOut otp={otp}/>
    </Drawer>
    </>
  );
};

export default CheckOut;
