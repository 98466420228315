import { Flex, InputNumber, Typography, Modal, Button } from 'antd';
import React, { useRef } from 'react';

const { Text } = Typography;


export default function ExpressCheckoutModal({paymentModalShow, setPaymentModalShow, posabitPayPhone, setPosabitPayPhone, submitPhoneNumber}) {

    const inputRef = useRef(null);

    React.useEffect(() => {
      if (paymentModalShow && inputRef.current) {
        inputRef.current.focus();
      }
    }, [paymentModalShow]);

    return (
      <Modal
        open={paymentModalShow}
        title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
        onCancel={() => setPaymentModalShow(false)}
        zIndex={1500}
        styles={{content: {padding: 50}}}
        footer={<Button block size='large' type='primary' onClick={submitPhoneNumber}>Submit</Button>}
        width={350}
      >
        <Flex vertical align="center">
          <Text style={{marginTop: 20, marginBottom: 20}}>Enter Mobile Number to Connect Bank</Text>

          <InputNumber
            size='large'
            controls={false}
            name='posabit_pay_phone'
            style={{width: '100%'}}
            placeholder='(###) ###-####'
            value={posabitPayPhone}
            onChange={(e) => setPosabitPayPhone(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitPhoneNumber()
              }
            }}
            formatter={(value) => {
              if (!value) return '';
              const phoneNumber = value.toString().replace(/\D/g, '');
              const areaCode = phoneNumber.slice(0, 3);
              const firstPart = phoneNumber.slice(3, 6);
              const secondPart = phoneNumber.slice(6, 10);
              return `(${areaCode}) ${firstPart}-${secondPart}`;
            }}
            parser={(value) => value.replace(/\D/g, '')}
            ref={inputRef}
          />
        </Flex >
      </Modal>
    )
}
