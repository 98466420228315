import React, { useEffect } from "react";
import { Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleMedicalOnly } from "../store/slice/MenuFeedAPISlice";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { putQuotesUpdateItemsApi } from '../store/InitializeQuotesPostAPI';
import { getReferenceNo } from '../utility/orderUtils';

export default function MenuType ({display_menu_types}) {
  const dispatch = useDispatch();
  const header = useSelector((state) => state.content?.contentData?.components.filter((c) => c.type === "heading")[0]);
  const location = useLocation();
  const products = useSelector((state) => state?.menuFeed?.info?.product_data?.menu_items);
  const isHomepage = location.pathname === "/";
  const isCategoryPage = location.pathname.startsWith("/category/"); // Check if it's a category page
  const isFilterPage = location.pathname === "/products"; // Check if it's a filter page
  const isSearchPage = location.pathname === "/products"; // Check if it's a search page


  useEffect(() => {
    const storedMenuType = sessionStorage.getItem("menuType");
    if (storedMenuType) {
      dispatch(toggleMedicalOnly(storedMenuType === "Medical"));
    } else {
      // Default to Recreational if nothing is stored
      dispatch(toggleMedicalOnly(false));
      sessionStorage.setItem("menuType", "Recreational");
    }
  }, [dispatch]);

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);
  const slugId = useSelector((store) => store?.venue?.info?.venue?.slug);


  const handleMenuTypeChange = (e) => {
    const selectedMenuType = e;

    sessionStorage.setItem("menuType", selectedMenuType);
    if (selectedMenuType === "Medical") {
      if(products?.filter(prod => prod?.variants?.some(v => v?.med_price_cents)).length === 0) {
        message.info({
          content: "No Medical products available",
          icon: <i className="fa fa-exclamation-circle" style={{color: 'red'}}/>,
        });
        return;
      }
      dispatch(toggleMedicalOnly(true)); // Set filter to true for Medical
    } else {
      dispatch(toggleMedicalOnly(false)); // Set filter to false for Recreational
    }
    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no: reference_no,
          quote: {
            customer: {
              customer_type: selectedMenuType === 'Medical' ? "medical" : "recreational",
            },
          },
        })
      )
    }
  };

  if( !display_menu_types ) return( null )

  return (
    <span className="address">
      <i className="fa fa-file-invoice"
        style={{color: `${header?.text_color}` || "#000"}}
      />
      {(isHomepage || isCategoryPage || isFilterPage || isSearchPage) ? (
        <Select
          className="location_pickup"
          onChange={handleMenuTypeChange}
          value={sessionStorage.getItem("menuType")}
          variant="borderless"
          dropdownStyle={{
            color: `${header?.text_color}` || "#000",
            backgroundColor: `${header?.heading_color}` || "#fff",
            width: isMobile ? "20%" :130,
          }}
          options={[{ value: "Recreational", label: isMobile ? "Rec" : 'Recreational' }, { value: "Medical", label: isMobile ? "Med" : 'Medical'}]}
          suffixIcon={<i className="fa fa-angle-down" style={{color: `${header?.text_color}` || "#000"}}/>}
        />
      ) : (
        // Render static text on other pages
        <span className="space"
          style={{color: `${header?.text_color}` || "#000"}}
        > {sessionStorage.getItem("menuType") === "Medical" ? "Medical" : "Recreational"}</span>
      )}
    </span>
  );
};

