import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { isMedMenu } from "../utility/generalUtils";
import { getReferenceNo } from '../utility/orderUtils';
import { Button, Flex, Space, Input, Form, InputNumber,Typography, Divider } from 'antd'
import { notNull } from "../sharedFunctions";
import { isMobile } from "react-device-detect";

const { Text, Paragraph } = Typography;

const UserInformation = ({setPaymentModalShow, setPlaidModalShow}) => {
  const [form] = Form.useForm();
  const columnWidth = isMobile ? '100%' : '80%';
  const [pageWidthBreak, setPageWidthBreak] = useState(window.innerWidth > 1200)
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const formData = quote?.customer;
  const dispatch = useDispatch();

  const [inputValues, setInputValue] = useState({
    first_name: formData?.first_name,
    last_name: formData?.last_name,
    email: formData?.email,
    phone: formData?.telephone,
  });

  useEffect(() => {
    sessionStorage.setItem("inputValues", JSON.stringify(inputValues));
  }, [inputValues]);

  useEffect(() => {
    const handleResize = () => setPageWidthBreak(window.innerWidth > 1200);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [])


  //handle submit updates

  function handleChange(event) {
    let name
    let value
    if (event?.target === undefined){
      name = 'phone'
      value = event || ''
    }else{
      name = event.target.name
      value = event.target.value
    }
    let formattedValue = value;
    if (name === 'first_name') {
      formattedValue = value.replace(/[^a-zA-Z]/g, '');
    }
    if (name === 'last_name') {
      formattedValue = value.replace(/[^a-zA-Z]/g, '');
    }
    if (name === 'phone') {
      formattedValue = value.toString() || '';
    }

    setInputValue({ ...inputValues, [name]: formattedValue });
  }

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reference_no) {
      updateUserInfo();
      // handleClick(2);
    }
  };

  const updateUserInfo = () => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          customer: {
            first_name: inputValues?.first_name,
            last_name: inputValues?.last_name,
            email: inputValues?.email,
            telephone: inputValues?.phone,
            customer_type: isMedMenu() ? "medical" : "recreational",
          },
        },
      })
    );
  }

  useEffect(() => {
    if (notNull(formData)) {
      setInputValue({
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        email: formData?.email,
        phone: formData?.telephone,
      });
    }
  }, [formData]);

  const firstNameField = <Form.Item
    name="firstName"
    style={{width: pageWidthBreak ? '48%' : '100%'}}
    rules={[
      {required: true, message: 'Please input your First Name!' },
    ]}
  >
    <Text></Text>
    <Input
      size='large'
      name="first_name"
      placeholder="First Name"
      onChange={(e) => handleChange(e)}
      onBlur={updateUserInfo}
      value={inputValues?.first_name}
      defaultValue={inputValues?.first_name}
      style={{width: '100%', marginBottom: 10}}
    />
  </Form.Item>

  const lastNameField = <Form.Item
    name="last_name"
    style={{width: pageWidthBreak ? '48%' : '100%'}}
    rules={[
      { required: true, message: 'Please input your Last Name!' },
    ]}
    >
      <Text></Text>
      <Input
        size='large'
        name='last_name'
        onChange={(e) => handleChange(e)}
        onBlur={updateUserInfo}
        value={inputValues.last_name}
        defaultValue={inputValues.last_name}
        placeholder="Last Name"
        style={{width: '100%', marginBottom: 10}}
    />

  </Form.Item>

  const emailField = <Form.Item
    name="email"
    style={{width: pageWidthBreak ? '48%' : '100%'}}
    rules={[
      { type: 'email', message: 'The input is not valid E-mail!' },
      { required: true, message: 'Please input your E-mail!' },
    ]}
  >
    <Text></Text>
    <Input
      size='large'
      name='email'
      onChange={(e) => handleChange(e)}
      onBlur={updateUserInfo}
      value={inputValues.email}
      defaultValue={inputValues.email}
      placeholder="Email" style={{width: '100%', marginBottom: 10}}/>
  </Form.Item>

  const phoneField = <Form.Item
    name="phone"
    style={{width: pageWidthBreak ? '48%' : '100%'}}
    rules={[
      { required: true, message: 'Please input your phone number!' },
    ]}
  >
    <Text></Text>
    <InputNumber
      size='large'
      placeholder="Phone Number"
      controls={false}
      name='phone'
      onChange={(e) => handleChange(e)}
      value={inputValues.phone}
      defaultValue={inputValues.phone}
      onBlur={updateUserInfo}
      style={{width: '100%', marginBottom: 10}}
      formatter={(value) => {
        if (!value) return '';
        const phoneNumber = value.toString().replace(/\D/g, '');
        const areaCode = phoneNumber.slice(0, 3);
        const firstPart = phoneNumber.slice(3, 6);
        const secondPart = phoneNumber.slice(6, 10);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }}
      parser={(value) => value.replace(/\D/g, '')}
    />
  </Form.Item>


  return (
    <div className="" style={{width: columnWidth, margin: 'auto'}}>
      {
        !quote?.payment?.posabit_pay_authorized &&
        <>
        <Paragraph>Express Checkout</Paragraph>
        <Flex align="center" vertical>
          <Button block color='default' size='large'
          onClick={() => setPaymentModalShow(true)}
          style={{ marginBottom:12}}
          >
            <img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 130}}/>
          </Button>
          <Space direction='horizontal' style={{marginBottom: 0, paddingLeft: 25}}>
            <Text style={{fontSize: 14}}>Pay by Bank</Text>
            <Text style={{fontSize: 12}} type='secondary'>Powered by</Text>
            <img src="/images/Plaid_logo.svg.png" alt="plaid" style={{width: 60}}/>
            <Text type='secondary'>
              <i className="fa fa-info-circle" style={{width: 15}} onClick={() => setPlaidModalShow(true)}/>
            </Text>
          </Space>
        </Flex>
        <div style={{ margin: 'auto'}}>
          <Divider style={{borderColor: '#d9d9d9'}}><Text type='secondary'>Or</Text></Divider>
        </div>
        </>
      }
      <Form
        form={form}
        name="userInformation"
        onFinish={handleSubmit}
        scrollToFirstError
        layout="inline"
        style={{width: '100%'}}
      >
        <Flex justify="space-between" style={{width: '100%'}} wrap={!pageWidthBreak}>
          {firstNameField}
          {lastNameField}
        </Flex>
        <Flex justify="space-between" style={{width: '100%'}} wrap={!pageWidthBreak}>
          {emailField}
          {phoneField}
        </Flex>
      </Form>
    </div>
  );
};
export default UserInformation;
