import { Space, Modal, Typography, Button, Flex } from 'antd';
import React from 'react'

const { Text } = Typography;

export default function ManageAccountModal({manageAccountShow, setManageAccountShow}) {

  return(
    <Modal
      open={manageAccountShow}
      title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
      onCancel={() => setManageAccountShow(false)}
      zIndex={1500}
      styles={{content: {padding: 50}}}
      footer={null}
      width={350}
    >
      <Space direction='vertical'  style={{marginTop: 20, textAlign: 'center'}}>
      <Text type='danger'>
      Unable to connect to your bank, select <Text type='danger' strong>Manage Account</Text> to re-link your bank account.
      </Text>
      <Button block size='large' type='primary'
        onClick={() => {
          window.open('https://secure.posabit.com/Payments/PaymentSource', '_blank');
        }}
        >
        Manage Account
        </Button>
      <Button block size='large' type='default' onClick={() => setManageAccountShow(false)}>
        <Text type='secondary'>
          Cancel
        </Text>
      </Button>
      </Space>
    </Modal>
  )
}
