import React, { useState } from 'react'
import { Typography, Divider, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { putQuotesUpdateItemsApi } from '../store/InitializeQuotesPostAPI';
import { getReferenceNo } from '../utility/orderUtils';
import { isMobile } from 'react-device-detect';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const AdditionalNotes = () => {
  const [notes, setNotes] = useState('');
  const dispatch = useDispatch();
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);
  const columnWidth = isMobile ? '100%' : '80%';
  const slugId = useSelector(
        (store) =>
          store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
            ?.slug
      );
    const defaultSlug = slugId;

  const handleNotes = () => {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            fulfillment: {
              notes: notes,
            }
          },
        })
      );
    }
  return (
    <>
    <Title level={4}>Additional Order Details</Title>
      <div style={{width: columnWidth, margin: 'auto'}}>
        <Paragraph>Notes</Paragraph>
        <TextArea
          rows={4}
          placeholder="Add a note to your order"
          value={notes}
          onChange ={(e) => setNotes(e.target.value)}
          onBlur={handleNotes}
        />
      </div>
      {
        isMobile &&
        <Divider style={{borderColor: '#d9d9d9'}} />
      }
    </>
  )
}
export default AdditionalNotes;
