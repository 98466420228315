import {
  Badge,
  Button,
  Card,
  Typography,
  Divider,
  Space,
  Flex,
  Select,
  ConfigProvider,
  message,
  Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import * as Constant from "../Constants";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { postInitializeQuotesApi, putQuotesUpdateItemsApi } from '../store/InitializeQuotesPostAPI';
import TestResult from './TestResult';
import ReactCardFlip from 'react-card-flip';
import LineageTag from './LineageTag';
import WeightRange from './WeightRange';
import { notNull } from '../sharedFunctions';
import { productDetailUrl } from '../utility/productUtils';
import { isMedMenu } from '../utility/generalUtils';
import { getReferenceNo } from '../utility/orderUtils';
import { parseFiltersfromURL } from '../utility/productUtils';
import { changeVariant } from '../store/slice/MenuFeedAPISlice';
import { filterTime } from '../utility/filterUtils';

const { Text, Paragraph } = Typography


export default function DynamicProductCard ({product, vertical}) {
  const navigate = useNavigate();
  const settings = useSelector((state) => state?.content?.contentData?.menu);
  const filterKey = useSelector((state) => state?.content?.contentData?.filters) || [];
  const discounts = useSelector((state) => state?.menuFeed?.info?.product_data?.discounts);
  const categories = useSelector((state) => state?.menuFeed?.info?.product_data?.categories);
  const [selectOpen, setSelectOpen] = useState(false);
  const [discount, setDiscount] = useState();
  const [med_discount, setMedDiscount] = useState();
  const [waiting, setWaiting] = useState(false);
  const [variant, setVariant] = useState();
  const [isFlipped, setIsFlipped] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const items = useSelector((store) => store?.quote?.info?.quote?.items);
  const sdpData = useSelector((store) => store?.sdpData?.info?.categories);
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const activeFilters = parseFiltersfromURL(filterKey.map((f) => f.filter_type));
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues?.filter((vn) => vn.selected)?.[0]
        ?.slug
  );
  const defaultSlug = slugId;
  const sdpCategory = sdpData?.find(
    (category) => category?.sdp_category === product?.sdp_category
  ) || null;

  const reference_no = getReferenceNo(venue_id);

  useEffect(() => {
    let selectedVariant = null;
    let resetVariant = false;
    if (variant && !product?.variants?.some((v) => v.id === variant.id)) {resetVariant = true}
    if ( (resetVariant || !notNull(variant)) && product?.variants?.length > 0 ) {
      if(notNull(activeFilters?.['weights'])){
        selectedVariant = product?.variants.find((v) => activeFilters?.['weights']?.includes(v?.display_weight) ) || product?.variants?.[0];
      } else {
        selectedVariant = product?.variants?.[0];
      }
    } else {
      selectedVariant = variant;
    }

    setVariant(selectedVariant);
  }, [activeFilters, product, variant])

  useEffect(() => {
    setWaiting(false)
    setIsFlipped(false)
  }, [items])

  useEffect(() => {
    if(notNull(variant)){
      setDiscount(discounts.find((d) => d.id === filterTime(discounts, variant.best_discount_ids)?.shift()?.id))
      setMedDiscount(discounts.find((d) => d.id === filterTime(discounts, variant.best_med_discount_ids)?.shift()?.id))
    }
  }, [variant, discounts])

  const displayPrice = (item) => {
    if (variant) {
      const discount_type = discount?.coupon_type;
      const discount_amount = discount?.amount;
      const med_discount_type = med_discount?.coupon_type;
      const med_discount_amount = med_discount?.amount;

      let calculatedTotal
      if(discount_type === 'percent'){
        calculatedTotal = (variant.price_cents - (variant.price_cents * discount_amount / 10000)) / 100
      } else if(discount_type === 'dollar') { calculatedTotal = (variant.price_cents - discount_amount) / 100 }
      else{ calculatedTotal = discount_amount / 100}

      let medCalculatedTotal
      if(med_discount_type === 'percent'){
        medCalculatedTotal = (variant.med_price_cents - (variant.med_price_cents * med_discount_amount / 10000)) / 100
      } else if(med_discount_type === 'dollar') { medCalculatedTotal = (variant.med_price_cents - med_discount_amount) / 100 }
      else{ medCalculatedTotal = med_discount_amount / 100}

      const regularPrice = variant.price_cents
        ? variant.price_cents / 100
        : null;

      const regularMedPrice = variant.med_price_cents
        ? variant.med_price_cents / 100
        : null;

      if(regularPrice === null || regularMedPrice === null){return "$0.00"}
      if( isMedMenu() && regularMedPrice !== null ){
        if( notNull(medCalculatedTotal) && notNull(med_discount) ){
          return (
            <span>
              <strike>${regularMedPrice.toFixed(2)}</strike>
              <span className="price">
                &nbsp;&nbsp;${medCalculatedTotal.toFixed(2)}
              </span>
            </span>
          );
        } else {
          return <Text strong >{`$${regularMedPrice.toFixed(2)}`}</Text>;
        }
      } else {
        if( notNull(calculatedTotal) && notNull(discount) ){
          return (
            <Space wrap>
              <Text delete>${regularPrice.toFixed(2)}</Text>
              <Text className="price">
                ${calculatedTotal.toFixed(2)}
              </Text>
            </Space>
          );
        } else if( regularPrice !== null ){
          return <Text strong >{`$${regularPrice.toFixed(2)}`}</Text>;
        } else {
          return "N/A";
        }
      }
    }
  };

  const changeLocalVariant = (e) => {
    const variant = product?.variants?.filter(
      (v) => v.id === e
    )?.[0];
    setVariant(variant);
    setSelectOpen(false);
  }

  const increment = () => {
    if(variant?.quantity_on_hand > 0 && quantity < variant?.quantity_on_hand ){
      setQuantity(quantity + 1);
    } else {
      message.warning({
        content: "You have reached the maximum quantity",
        icon: <i className="fa fa-info-circle" style={{color: 'blue'}}/>
    });
    }
  }

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  const chosenDiscountName = discount?.item_display_name;
  const image = () => {
    let mapping = {
      "Flower": Constant.IMAGES.flower,
      "Edible Liquid": Constant.IMAGES.edible_liquid,
      "Edible Solid": Constant.IMAGES.edible_solid,
      "Paraphernalia": Constant.IMAGES.paraphernalia,
      "Preroll": Constant.IMAGES.pre_roll,
      "Topical": Constant.IMAGES.topical,
      "Sample": Constant.IMAGES.sample,
      "Concentrate": Constant.IMAGES.concentrate,
      }
      const category = categories?.filter(c => c.id === product?.category_ids?.[0])?.[0]
      if (category && category.name in mapping) {
        return mapping[category.name]
      }else{ return Constant.FLOWER_IMAGE }
    }

    const addToCart = () => {
      // Disable the button
      // setDisabledButtons((prevDisabledButtons) => ({
      //   ...prevDisabledButtons,
      //   [selectedProduct.id]: true,
      // }));
      setWaiting(true)
      try {
        const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
        const existingCartItem = items?.find(
          (it) =>
            it.sku_id === product?.sku_id &&
            it.unit === variant?.unit &&
            it.unit_type === variant?.unit_type
        );

        if (
          existingCartItem &&
          existingCartItem.quantity === variant?.quantity_on_hand
        ) {
          setWaiting(false)
          message.warning({
            content: "You have reached the maximum quantity",
            icon: <i className="fa fa-info-circle" style={{color: 'blue'}}/>
        });
        } else {
          if (reference_no) {
            const found = items?.filter((it) => {
              if (
                it.sku_id === product?.sku_id &&
                it.unit === variant?.unit &&
                it.unit_type === variant?.unit_type
              ) {
                return it;
              }else{
                return null;
              }
            });
            if (found?.length > 0) {
              dispatch(
                putQuotesUpdateItemsApi({
                  slug: slugId,
                  reference_no,
                  quote: {
                    items: [
                      ...items.map((it) => {
                        if (
                          it.sku_id === variant?.sku_id &&
                          it.unit === variant?.unit &&
                          it.unit_type === variant?.unit_type
                        ) {
                          return {
                            sku_id: it?.sku_id,
                            unit: it?.unit,
                            unit_type: it?.unit_type,
                            quantity: quantity
                          };
                        } else {
                          return {
                            sku_id: it?.sku_id,
                            unit: it?.unit,
                            unit_type: it?.unit_type,
                            quantity: it?.quantity,
                          };
                        }
                      }),
                    ],
                  },
                })
              );
              message.success({
                content: "Product quantity updated in cart",
                icon: <i className="fa fa-check-circle" style={{color: 'green'}}/>
              });
            } else {
              dispatch(
                putQuotesUpdateItemsApi({
                  slug: slugId,
                  reference_no,
                  quote: {
                    items: [
                      {
                        sku_id: variant?.sku_id,
                        unit: variant?.unit,
                        unit_type: variant?.unit_type,
                        quantity: quantity,
                      },
                      ...items.map((it) => {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }),
                    ],
                  },
                })
              );
              message.success({
                content:"Product Added to cart",
                icon: <i className="fa fa-check-circle" style={{color: 'green'}}/>
              });
            }
          } else {
            dispatch(
              postInitializeQuotesApi({
                slug: slugId,
                quote: {
                  customer: {
                    customer_type: isMedMenu() ? "medical" : "recreational",
                  },
                  fulfillment:{
                    source: settings?.mode === "online" ? 'posabit_v2' : 'posabit_v2_instore',
                  },
                  items: [
                    {
                      sku_id: variant?.sku_id,
                      unit: variant?.unit,
                      unit_type: variant?.unit_type,
                      quantity: quantity,
                    },
                  ],
                },
              })
            );
            message.success({
              content: "Product Added to cart",
              icon: <i className="fa fa-check-circle" style={{color: 'green'}}/>
              });
          }
        }
      } catch (error) {
        // Handle error
        console.error("Error adding to cart", error);
        message.error({
          content: "Error adding to cart",
          icon: <i className="fa fa-exclamation-circle" style={{color: 'red'}}/>
        });
      }
  }

    const nameLink = (
    <Tooltip title={product?.name}>
      <Paragraph strong style={{margin: 0, minHeight: 66}} ellipsis={{ rows: 3 }}>
        {product?.name}
      </Paragraph>
    </Tooltip>
    )

    const handleDetailClick = (product) => {
      dispatch(changeVariant( { productId: product?.id, variantId: variant?.id } ))
      navigate(productDetailUrl(product),
                      {
                        state: {
                          brands: product?.brand_id,
                          product_id: product?.product_id,
                          selectedVariant: variant,
                        },
                      })
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const cover_image = () => variant?.images?.[0]?.md || product?.product_image?.md

    return (
      <div>
        {
          vertical ?
          <Card bordered={false}
            size='small'
            styles={{
              body: {
                padding: 0,
              }
            }}
          >
            <Flex justify='space-between' onClick={() => handleDetailClick(product)} style={{paddingBottom: 10}}>
              <div style={{width: '25%', paddingLeft: 10}} >
                <Badge.Ribbon
                  text={chosenDiscountName}
                  color={chosenDiscountName !== undefined ? settings?.secondary_color : 'rgb(0,0,0,0)'}
                  placement='start'
                >
                  <img
                    alt="product"
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      marginTop: 15
                    }}
                    src={cover_image()}
                    onError={(e) => {e.target.onerror = null; e.target.src = image()}}
                  />
                </Badge.Ribbon>
              </div>
              <div style={{width: '50%'}} >
              <Flex vertical
                justify='space-between'
              // align='center'
              >
                {nameLink}
                <Space direction='vertical'
                  style={{width: '100%'}}
                >
                  <div>

                    <div>
                      <Text type='secondary'>{product.brand}</Text>
                      {
                        product?.is_marijuana &&
                        <div >
                          {(
                            <TestResult type={settings?.primary_thc} item={product} />
                          )}
                          {(
                            <TestResult type={settings?.primary_cbd} item={product}/>
                          )}
                        </div>
                      }
                    </div>
                    <div>
                      {
                        ((product.flower_type && product.flower_type?.length !== 0) || sdpCategory) && (
                          <Space direction='horizontal' size={0}>
                            {product.flower_type && product.flower_type?.length !== 0 && (
                              <LineageTag lineage={product.flower_type}/>
                            )}
                            {sdpCategory && (
                              <img
                                alt={sdpCategory.name}
                                className={"sdp_category_icon"}
                                src={sdpCategory.icon_url}
                                style={{padding: 0}}
                              />
                            )}
                            {
                              variant?.doh_compliant &&
                              <img style={{width: 23}} src={window.location.origin + '/images/doh_image.png'} alt='doh compliant'/>
                            }
                          </Space>
                        )
                      }
                    </div>
                  </div>
                </Space>
                </Flex>
              </div>
              <Flex style={{width: '20%'}} align='baseline' justify='flex-end'>
                {displayPrice(product)}
              </Flex>
            </Flex>
              <Flex 
                // justify='space-between'
                align='center'
              >
                <div style={{width: '25%', paddingLeft: 10}}>
                  {
                    product?.variants?.length > 1  && notNull(variant)
                    ?
                      <ConfigProvider
                        theme={{
                          token:{
                            borderRadius: 7,
                          }
                        }}
                      >
                        <Select
                          // style={{width: 110}}
                          suffixIcon={<i className="fa fa-chevron-down" style={{color: 'grey'}}/>}
                          defaultValue={notNull(activeFilters?.['weights']) ? product?.variants.find((v) => activeFilters?.['weights']?.includes(v?.display_weight) )?.id : product?.variants?.[0]?.id}
                          autoFocus={false}
                          onBlur={() => setSelectOpen(false)}
                          onClick={() => setSelectOpen(!selectOpen)}
                          open={selectOpen}
                          onChange={changeLocalVariant}
                          options={product?.variants?.map((vt) => {
                              return {label: `${vt?.unit} ${vt?.unit_type}`, value: vt?.id}
                            })
                          }
                        />
                      </ConfigProvider>
                    :
                    <span>
                      {
                        product?.variants?.[0]?.unit === '0.0'
                        ? null
                        :
                        <>
                        {`${product?.variants?.[0]?.unit} ${product?.variants?.[0]?.unit_type}`}
                        </>
                      }
                    </span>
                  }
                </div>
                <Space size={13} style={{width: '50%', paddingLeft: 10}}>
                  <Button
                      shape='circle'
                      // size='small'
                      onClick={() => decrement()}
                      icon={<i className="fa fa-minus"/>}
                      type='text'
                      style={{
                        boxShadow: Constant.BOX_SHADOW
                      }}
                      disabled={quantity <= 1}
                    />
                    <Text>{quantity}</Text>
                    <Button
                      shape='circle'
                      // size='small'
                      onClick={() => increment()}
                      icon={<i className="fa fa-plus"/>}
                      type='text'
                      style={{
                        boxShadow: Constant.BOX_SHADOW
                      }}
                    />
                </Space>
                <Flex justify='flex-end' style={{width: '25%'}}>
                <Button
                  type="primary"
                  size='large'
                  loading={waiting}
                  onClick={() => addToCart(product, variant)}
                  style={{borderRadius: 7}}
                >
                  <Space>
                    <i className="fa fa-cart-shopping prd_dtl"/>
                    <Text style={{color: 'white'}}>Add</Text>
                  </Space>
                </Button>
                </Flex>
              </Flex>
            <Divider style={{marginTop: 10, marginBottom: 10}}/>
          </Card>
          :
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal"
          >
            <Card
              hoverable
              style={{
                margin: '10px',
                // width: 300,
              }}
              cover={
                <>
                  <Badge.Ribbon
                    text={chosenDiscountName}
                    color={chosenDiscountName !== undefined ? settings?.secondary_color : 'rgb(0,0,0,0)'}
                  />
                  <img
                    alt="example"
                    style={{
                      height: 160,
                      objectFit: 'contain',
                      padding: '1px',
                    }}
                    src={cover_image()}
                    onError={(e) => {e.target.onerror = null; e.target.src = image()}}
                  />
                </>
              }
              onClick={() => setIsFlipped(true)}
            >
              <Card.Meta
                // title={nameLink}
                // style={{height: 205}}
                description={
                  <Flex vertical
                  // justify='space-between'
                  // align='center'
                    // style={{height: 226 }}
                  >
                    {nameLink}

                    <Flex vertical justify='space-between'
                      style={{height: 182}}
                      >
                      <div>
                        <div>
                          <div>{product.brand}</div>
                          {
                            product?.is_marijuana &&
                            <div >
                              {(
                                <TestResult type={settings?.primary_thc} item={product} />
                              )}
                              {(
                                <TestResult type={settings?.primary_cbd} item={product}/>
                              )}

                            </div>
                          }
                        </div>
                        <div>
                          <Space direction='horizontal' size={0}>
                          {
                            ((product.flower_type && product.flower_type?.length !== 0) || sdpCategory) && (
                              <>
                                {product.flower_type && product.flower_type?.length !== 0 && (
                                  <LineageTag lineage={product.flower_type}/>
                                )}
                                {sdpCategory && (
                                  <img
                                    alt={sdpCategory.name}
                                    className={"sdp_category_icon"}
                                    src={sdpCategory.icon_url}
                                    style={{padding: 0}}
                                  />
                                )}
                              </>
                              )
                            }
                            {
                              variant?.doh_compliant &&
                              <img style={{width: 23}} src={window.location.origin + '/images/doh_image.png'} alt='doh compliant'/>
                            }
                            </Space>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: '100%'
                        }}
                      >
                        <WeightRange product={product} />
                        <Divider type='vertical'/>
                        {displayPrice(product)}
                      </div>
                    </Flex>
                  </Flex>
                }
              />
            </Card>

            <Badge.Ribbon
              text={chosenDiscountName}
              color={chosenDiscountName !== undefined ? settings?.secondary_color : 'rgb(0,0,0,0)'}
            >
            <Card
              hoverable
              style={{
                margin: '10px',
              }}
              actions={[
                <Button type='text' size='large' className="select_box"
                  style={{borderRadius:7}}
                  onClick={() => handleDetailClick(product)}
                >
                  <Text type='secondary'>
                    Details
                  </Text>
                </Button>,
                <Button
                  type="primary"
                  size='large'
                  loading={waiting}
                  // style={{background: settings?.secondary_color}}
                  onClick={() => addToCart(product, variant)}
                  // disabled={disabledButtons[product.id]}
                  style={{borderRadius: 7}}
                >
                  <Space>
                    <i className="fa fa-cart-shopping prd_dtl"/>
                    <Text style={{color: 'white'}}>Add</Text>
                  </Space>
                </Button>
              ]}
              title={
                <Space direction="vertical" style={{marginTop: 5}}>
                  <Text type='secondary' onClick={() => setIsFlipped(false)}>
                    <i className="fa fa-arrow-left"/>
                    Back</Text>
                </Space>
              }
            >
              <Card.Meta
                style={{
                  width: 'auto',
                  height: 265,
                  // margin: '10px'
                }}
                // onClick={() => setIsFlipped(false)}
                description={
                  <div>
                    <div onClick={() => handleDetailClick(product)}>
                      {nameLink}
                      {product.brand}
                      {
                        product?.is_marijuana &&
                        <div>
                          {(
                            <TestResult type={settings?.primary_thc === 'thc total' ? 'total_thc' : settings?.primary_thc} item={product} />
                          )}
                          {(
                            <TestResult type={settings?.primary_cbd} item={product}/>
                          )}
                        </div>
                      }
                      <div>
                        {product.flower_type && product.flower_type?.length !== 0 && (
                          <LineageTag lineage={product.flower_type} />
                          )
                        }
                        {
                          variant?.doh_compliant &&
                          <img style={{width: 23}} src={window.location.origin + '/images/doh_image.png'} alt='doh compliant'/>
                        }
                        {/* <p>{chosenProductTypeName}{Constant.FLOWER}</p> */}
                      </div>
                    </div>
                    <Flex justify='center'>
                      <Space direction='vertical' align='center'>
                        {displayPrice(product)}
                        {
                          product?.variants?.length > 1  && notNull(variant)
                          ?
                            <ConfigProvider
                              theme={{
                                token:{
                                  borderRadius: 7,
                                }
                              }}
                            >
                            <Select
                              suffixIcon={<i className="fa fa-chevron-down" style={{color: 'grey'}}/>}
                              style={{width: 110}}
                              defaultValue={notNull(activeFilters?.['weights']) ? product?.variants.find((v) => activeFilters?.['weights']?.includes(v?.display_weight) )?.id : product?.variants?.[0]?.id}
                              autoFocus={false}
                              onBlur={() => setSelectOpen(false)}
                              onClick={() => setSelectOpen(!selectOpen)}
                              open={selectOpen}
                              onChange={changeLocalVariant}
                              options={product?.variants?.map((vt) => {
                                return {label: `${vt?.unit} ${vt?.unit_type}`, value: vt?.id}
                              })
                            }
                            />
                            </ConfigProvider>
                          :
                            <span>{
                              product?.variants?.[0]?.unit === '0.0'
                              ? null
                              :
                              <>
                              {`${product?.variants?.[0]?.unit} ${product?.variants?.[0]?.unit_type}`}
                              </>
                              }</span>
                        }
                          <div className="plus_minus_box">
                            <div className="plus_minus">
                              <span
                                className={`minus ${quantity === 1
                                  ? "disabled"
                                  : ""
                                  }`}
                                onClick={() => decrement(variant)}
                              >
                                -
                              </span>

                              <span className="value">
                                {quantity}
                              </span>

                              <span
                                className={`add ${variant?.quantity_on_hand === quantity
                                  ? "disabled"
                                  : ""
                                  }`}
                                onClick={() => increment(variant)}
                              >
                                +
                              </span>
                            </div>
                          </div>
                      </Space>
                    </Flex>
                  </div>
                }
              />

            </Card>
            </Badge.Ribbon>
          </ReactCardFlip>
        }
      </div>
    )
}
