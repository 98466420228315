import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import * as Constant from "../Constants";
import QuoteBreakdown from "./QuoteBreakdown";
import { Typography, Card, Collapse } from 'antd'
import { isMobile } from "react-device-detect";

const { Title } = Typography;

const Review = () => {
  const navigate = useNavigate();
  // const { product_types, strains, menu_items } = product_data;
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };

  const items = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );





  return (
    <>
    {
      isMobile ?
      <Collapse defaultActiveKey={['']} size='large'>
        <Collapse.Panel header={<Title level={5} style={{marginBottom: 0}}>In Your Cart</Title>} key="1">
          {items?.length === 0 ? (
            <div className="empty_cart text-center container">
              <img src="./images/Group 778.png" alt='empty cart'/>
              <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
                {Constant.RETURN_TO_SHOPPING}
              </button>
            </div>
          ) : (
            <div className="">
              <div className="" style={{padding:15}}>
                <QuoteBreakdown />
              </div>
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
      :
      <Card title='In Your Cart' className='dark-border'>
        {items?.length === 0 ? (
          <div className="empty_cart text-center container">
            <img src="./images/Group 778.png" alt='empty cart'/>
            <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
              {Constant.RETURN_TO_SHOPPING}
            </button>
          </div>
        ) : (
          <div className="">
            <div className="" style={{padding:15}}>
              <QuoteBreakdown />
            </div>
          </div>
        )}
      </Card>

    }
    </>
  );
};

export default Review;
