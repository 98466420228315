import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { putQuotesUpdateItemsApi } from '../store/InitializeQuotesPostAPI';
import { Alert, message, Typography, Flex, Row, Col, Button, Space } from 'antd';
import { isMobile } from 'react-device-detect';
import { getReferenceNo } from '../utility/orderUtils';
import { productDetailUrl } from '../utility/productUtils';
import { useNavigate } from 'react-router-dom';
import * as Constant from '../Constants';

const { Text, Paragraph } = Typography;


const QuoteBreakdown = () => {
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const items = quote?.items;
  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);
  const product_data = useSelector(state => state?.menuFeed?.info?.product_data);
  const discounts = useSelector(state => state?.discountData?.info?.coupons)
  const secondary_color = useSelector(state => state?.content?.contentData?.menu?.secondary_color);
  const product_types = product_data?.product_types;
  const strains = product_data?.strains;
  const menu_items = product_data?.menu_items;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;

  const showWeight = (prod) => {
    const product = menu_items?.filter(p => p?.variants?.some(v => v?.product_id === prod?.product_id))[0];
    const isType = ['Flower', 'Concentrate'].includes(product_types?.find(p => p.id === product?.product_type_id)?.name)
    return isType;
  }

  const changeFinalQuantity = (item, inc) => {
    if (isIconDisabled) {
      return; // If the icon is disabled, do not update the quantity
    }
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlugId;

    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          items: items.map((it) => {
            if (
              it.sku_id === item?.sku_id &&
              it.unit === item?.unit &&
              it.unit_type === item?.unit_type
            ) {
              const newQuantity = inc ? it.quantity + 1 : it.quantity - 1;
              const updatedQuantity = Math.min(
                Math.max(newQuantity, 1), // Ensure quantity is at least 1
                it.quantity_on_hand // Ensure quantity doesn't exceed quantity_on_hand
              );

              if (updatedQuantity !== it.quantity) {
                dispatch({
                  type: 'PUT_QUOTES_UPDATE_ITEMS',
                  payload: {
                    sku_id: it.sku_id,
                    unit: it.unit,
                    unit_type: it.unit_type,
                    quantity: updatedQuantity,
                  },
                });
                message.success({
                  content: "Product quantity updated in cart",
                  icon: <i className="fa fa-check" style={{color: 'green'}}/>,
                });
              } else if (updatedQuantity === 1 && !inc) {
                message.warning({
                  content: "Minimum 1 quantity required",
                  icon: <i className="fa fa-exclamation-circle" style={{color: 'orange'}}/>,
                });
              } else if (updatedQuantity === it.quantity) {
                message.warning({
                  content: "You have reached the maximum quantity",
                  icon: <i className="fa fa-exclamation-circle" style={{color: 'orange'}}/>,
                });
              }

              return { ...it, quantity: updatedQuantity };
            }

            return it;
          }),
        },
      })
    );
    // Disable the icon for 15 milliseconds
    if (inc) {
      setIsIconDisabled(true);
      setTimeout(() => {
        setIsIconDisabled(false);
      }, 2000);
    }
  };

  const removeFromCart = (item) => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlugId;
    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            items: [
              ...items
                .filter((it) => {
                  return it.sku_id !== item.sku_id;
                })

                .map((its) => {
                  return {
                    sku_id: its?.sku_id,
                    unit: its?.unit,
                    unit_type: its?.unit_type,
                    quantity: its?.quantity,
                  };
                }),
              message.info({
                content: "Item removed from cart",
                icon: <i className="fa fa-exclamation-circle" style={{color: 'blue'}}/>,
              }),
            ],
          },
        })
      );
    }
  };

  const displayName = (product_id, sku_id) => {
    const product = menu_items?.filter(p => p?.variants?.some(v => v?.product_id === product_id))[0];
    const variant = product?.variants?.filter(v => v.sku_id === sku_id)[0];
    const product_type = product_types?.filter(p => p.id === product?.product_type_id)[0]?.name;
    if (product_type === 'Flower' && product?.strain_id !== null) {
      const strain_name = strains?.filter(s => s.id === product?.strain_id)?.[0]?.name;
      return strain_name;
    } else {
      return variant?.name;
    }
  }

  const discountNames =  items?.map((item, item_index) => {
    const itemDiscounts = Array.from(new Set(item.discount_ids)).map(discountId => {
      const discount = discounts?.find(d => d.id === discountId);
      return discount ? discount.display_name || discount.name : null;
    });

    return {
      itemId: item_index,
      discounts: itemDiscounts,
    };
  });

  const handleDetailClick = (item) => {
    const product = menu_items?.filter(p => p?.product_id === item?.product_id)[0];
    const variant = product?.variants?.filter(v => v.sku_id === item?.sku_id)[0];
    navigate(productDetailUrl(product),
                    {
                      state: {
                        brands: product?.brand_id,
                        product_id: product?.product_id,
                        selectedVariant: variant,
                      },
                    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className="">
      {
        quote?.limits?.exceeded_limits.length > 0 &&
        <Alert message={quote?.limits?.exceeded_limits[0]} type="error" showIcon />
      }
      {items?.map((item, item_index) => {
        // Find the corresponding discounts for the current item
        const itemDiscount = discountNames?.find(d => d.itemId === item_index);
        // const quantityArr = new Array(
        //   item?.quantity_on_hand
        // ).fill("");
        return (
          <div key={item.sku_id}>
          {
            isMobile ?
            <Flex justify='space-between' align='flex-start' style={{marginBottom: 20}}>
              <img
                style={{width: '25%'}}
                src={item?.product_image?.lg}
                alt="Product_Image"
              />
              <div style={{width: '75%', paddingLeft: 5}}>
                <Row onClick={() => handleDetailClick(item)}>
                  <Col style={{width: '70%'}}>
                    <Paragraph strong ellipsis={{ rows: 2 }} style={{marginBottom: 0}}>
                      {displayName(item?.product_id, item?.sku_id)}
                    </Paragraph>
                    {
                        item?.discount > 0 ?
                        <div>
                            {
                              parseFloat(item?.unit) > 0.0 && showWeight(item) &&
                              <Text type='success' strong>
                                $ {((item.price / 100) - (item?.discount / 100 / item?.quantity)).toFixed(2)}
                                  <span> / {item?.unit + item?.unit_type}</span>
                              </Text>
                            }
                          {
                            itemDiscount?.discounts.map((discountName, index) => (
                              <Paragraph key={index} type='success' style={{marginBottom: 0}}>
                                {" "}
                                {discountName && (
                                  <><i className="fa fa-tag"/>{" "}<span>{discountName}</span></>
                                )}

                              </Paragraph>
                            ))
                          }
                        </div>
                        :
                        <Paragraph style={{marginBottom: 0}}>
                          $ {(item.price / 100).toFixed(2)}
                          {
                            parseFloat(item?.unit) > 0.0 && showWeight(item) &&
                            <span> / {item?.unit + item?.unit_type}</span>
                          }
                        </Paragraph>

                    }
                  </Col>
                  <Col style={{width: '30%'}}>
                    <Paragraph style={{
                      marginBottom: 0,
                      textAlign: 'right'
                    }}>
                      {
                        item?.discount > 0 ?
                          <>
                            <Paragraph delete style={{marginBottom: 0}}>
                              $ {(item.price / 100 * item?.quantity).toFixed(2)}
                            </Paragraph>
                            <Paragraph type='success' strong style={{marginBottom: 0}}>
                              $ {(item.price / 100 * item?.quantity - (item?.discount / 100)).toFixed(2)}
                            </Paragraph>
                          </>
                        :
                          <Paragraph style={{marginBottom: 0}}>
                            $ {(item.price / 100 * item?.quantity).toFixed(2)}
                          </Paragraph>
                      }

                    </Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Paragraph type='secondary' style={{marginBottom: 6}}>
                    Quantity
                  </Paragraph>
                  <Flex justify='space-between' align='center' style={{width: '100%'}}>
                    <Space
                      size={13}
                    >
                    <Button
                      shape='circle'
                      // size='small'
                      onClick={() => changeFinalQuantity(item, false)}
                      icon={<i className="fa fa-minus"/>}
                      type='text'
                      style={{
                        boxShadow: Constant.BOX_SHADOW
                      }}
                      disabled={item?.quantity <= 1}
                    />
                    <Text>{item?.quantity}</Text>
                    <Button
                      shape='circle'
                      // size='small'
                      onClick={() => changeFinalQuantity(item, true)}
                      icon={<i className="fa fa-plus"/>}
                      type='text'
                      style={{
                        boxShadow: Constant.BOX_SHADOW
                      }}
                    />
                    </Space>
                    <Button
                      shape='circle'
                      type='text'
                      size='large'
                      onClick={() => removeFromCart(item)}
                      icon={<i className="fa fa-trash"/>}
                    />
                  </Flex>
                </Row>
              </div>
            </Flex>
            :
            <div className="productminicart card_2" key={item?.sku_id}>
            <div className="img_box" onClick={() => handleDetailClick(item)}>
              <img
                src={item?.product_image?.lg}
                alt="Product_Image"
              />
            </div>
            <div className="det_box">
              <div className="d-flex pt-2" onClick={() => handleDetailClick(item)}>
                <Paragraph strong ellipsis={{ rows: 2 }} wrap={true} style={{marginBottom: 0}}>
                  {displayName(item?.product_id, item?.sku_id)}
                </Paragraph>
                {/* <h1 className="name">{displayName(item?.product_id, item?.sku_id)}</h1> */}
                {
                  item?.discount > 0 ?
                  <div className="static">
                    {
                      parseFloat(item?.unit) > 0.0 && showWeight(item) &&
                      <>
                      $ {((item.price / 100) - (item?.discount / 100 / item?.quantity)).toFixed(2)}
                      <span>/ {item?.unit + item?.unit_type}</span>
                      </>
                    }
                    {
                    itemDiscount?.discounts.map((discountName, index) => (
                      <p key={index}>
                        {" "}
                        {discountName && (
                          <Text type='success'><i className="fa fa-tag"/>{" "}<span>{discountName}</span></Text>
                        )}
                      </p>
                    ))}
                  </div>
                  :
                  <div style={{color: 'black'}}>
                    $ {(item.price / 100).toFixed(2)}
                    {
                      parseFloat(item?.unit) > 0.0 && showWeight(item) &&
                      <span> / {item?.unit + item?.unit_type}</span>
                    }
                  </div>
                }
                <div>
                  <span>
                    {/* {item?.unit} {item?.unit_type} */}
                  </span>

                  {/* <p>
                    <span>{item?.quantity} x</span> $
                    {(item?.price / 100).toFixed(2)}
                  </p> */}
                </div>
              </div>

              { item?.discount > 0
              ?
              <div>
              <div className="price"><strike>$ {(item.price / 100 * item?.quantity).toFixed(2)}</strike></div>
              <div className="price" style={{color: secondary_color}}>$ {(item.price / 100 * item?.quantity - (item?.discount / 100)).toFixed(2)}</div>
              </div>
              :
              <div className="price">$ {(item.price / 100 * item?.quantity).toFixed(2)}</div>
            }
              <div className="itemquantity position-relative">

                <div className="plus_minus_box box_label">
                  <label>Quantity</label>
                  <div className="plus_minus">
                    <span
                      className={`minus ${item?.quantity <= 1
                        ? "disabled"
                        : ""
                        }`}
                      onClick={() =>
                        changeFinalQuantity(item, false)
                      }
                    >
                      -
                    </span>
                    <span className="value">
                      {item?.quantity}
                    </span>
                    <span
                      className={`add ${isIconDisabled ? "disabled" : ""}`}
                      onClick={() =>
                        changeFinalQuantity(item, true)
                      }
                    >
                      +
                    </span>
                  </div>
                </div>
                <i
                  className="fa fa-trash"
                  onClick={() => removeFromCart(item)}
                />
              </div>
            </div>
          </div>
          }


          </div>
        );
      })}
    </div>
  )
}

export default QuoteBreakdown
